<template>
  <div>
    <!--begin::Notifications-->
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Notifications List</h3>
        </div>
        <div class="card-toolbar">
          <div class="example-tools justify-content-center">
            <div class="my-2">
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <form class="form" novalidate="novalidate" id="kt_notification_form">
          <!--begin::Example-->
          <div class="example mb-10">
            <v-card>
              <v-data-table :items="notification_list">
                <template v-slot:item="row">
                  <tr>
                    <td>
                    <a @click="openLink(row.item)" class="navi-item">
                      <div class="navi-link">
                        <div class="navi-text">
                          <div :class="row.item.read_at ? '' : 'font-weight-bold'">
                            <span v-html="row.item.data"></span>
                          </div>
                          <div class="text-muted">
                          {{ getDuration(row.item.created_at) }}
                          </div>
                        </div>
                      </div>
                    </a>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </div>
        </form>
      </div>
    </div>
    <!--end::Notifications-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Vuetify from "vuetify";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { MAKE_READ } from "@/core/services/store/event.module";
import Swal from "sweetalert2";
import axios from "axios";
import moment from "moment";

export default {
  name: "notificationList",
  vuetify: new Vuetify(),
  components: {},
  data() {
    return {
      notification_list: []
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    config() {
      return this.layoutConfig();
    }
  },
  mounted() {
    // set the tab from previous
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Notifications" }]);
    this.getNotificationsList();
  },
  destroyed() {
    localStorage.removeItem("builderTab");
  },
  methods: {
    getNotificationsList() {
      let context = this;
      let api_url = "notifications";
      axios({
        method: "get",
        url: api_url,
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          context.notification_list = result.data.result;
        },
        function(error) {
          Swal.fire(error, "", "info");
        }
      );
    },
    getDuration(then_time) {
      var now_time = moment(new Date());
      var then_time_mom = moment(then_time);
      return moment.duration(now_time.diff(then_time_mom)).humanize();
    },
    openLink(datas) {
      let context = this;
      this.$store.dispatch(MAKE_READ, {id: datas.type_id, type:datas.type});
      if(datas.type == 'Event'){
          context.$router.push({
            name: "events.view",
            params: { id: datas.type_id }
          });
      }else if(datas.type == 'Task'){
          context.$router.push({
            name: "mytasks"
          });
      }else if(datas.type == 'Training'){
          context.$router.push({
            name: "training.enroll", 
            params: { id: datas.type_id }
          });
      }else if(datas.type == 'TrainingIncomplete'){
          context.$router.push({
            name: "training.enroll", 
            params: { id: datas.type_id }
          });
      }else if(datas.type == 'AddBusinessAccount'){
          context.$router.push({
            name: "businessAccountList",
          });
      }else if(datas.type == 'BeforeSubscription'){
          context.$router.push({
            name: "Subscription",
          });
      }else if(datas.type == 'DocumentRenewal'){
          context.$router.push({
            name: "documents.renew",
            params: { id: datas.type_id }
          });
      }else if(datas.type == 'BusinessAccountRenewal'){
          context.$router.push({
            name: "businessAccount.edit",
            params: { id: datas.type_id }
          });
      }
    }
  }
};
</script>
